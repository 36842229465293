import React from "react";
import { Link } from "react-router-dom";
import "./Service.css";

const Home_service = () => {
  return (
    <>
      <section className="services">
        <div className="container">
          <Link className="service_box" to="/Microfinance">
            <div className="service_content">
              <h5>Microfinance</h5>
              <p>Sampurna believes in providing members of the lower income group of our society with access to opportunities for comprehensive financial and business development.</p>
              <span>LEARN MORE</span>
            </div>
          </Link>
          <Link className="service_box" to="/Skills">
            <div className="service_content">
              <h5>Skills Development</h5>
              <p>Our skill developmental training programmes support the comparaitively lagging people to heal their root cause of poor living standards by helping them realize their passion and career is imperative.</p>
              <span>LEARN MORE</span>
            </div>
          </Link>
          <Link className="service_box" to="/Socialservice">
            <div className="service_content">
              <h5>Social Services</h5>
              <p>Sampurna also offers a wide range of social services in order to improve the standard of living of the vulnerable families.</p>
              <span>LEARN MORE</span>
            </div>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Home_service;
