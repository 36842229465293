import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import SkillComp from "../Skills_component/SkillComp.js";

function Skills() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Skills Development" image={require('../../resource/Banner/skill.jpeg')}/>
      <SkillComp />
      </>
    );
  }
  
  export default Skills;