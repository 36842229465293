import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import ContactComp from "../ContactUS_component/ContactComp.js";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Contact Us" image={require('../../resource/Banner/contact.jpg')}/>
      <ContactComp />
      </>
    );
  }
  
  export default Contact;