import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "./Home_hero.css";
import { Autoplay } from "swiper";

const Home_hero = () => {
  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="back_img" id="back_img1">
            <div className="hero_box_contain">
              <h2>
                Finance with <br />
                <span>humanitarian service</span>
              </h2>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="back_img" id="back_img2">
            <div className="hero_box_contain" id="hero_box_contain_skill">
              <h2>Skill to Shape <br /><span> a better future</span></h2>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="back_img" id="back_img3">
            <div className="hero_box_contain">
              <h2>Assistance just a <br /><span> call away</span></h2>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <section className="sampurna">
        <div className="container">
          <div className="sam_left">
            <h1>SAMPURNA</h1>
          </div>
          <div className="sam_right">
            <p>
              Sampurna is a social-microfinance organization that aims to foster
              wholesome development of the comparatively vulnerable members of
              our society.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home_hero;
