import React, { useEffect } from 'react';
import BackgroundCom from "../Background_component/BackgroundCom.js";
import Hero  from "../Hero_component/Hero.js";

function Background() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Organization Background" image={require('../../resource/Banner/Background.jpg')}/>
      <BackgroundCom />
      </>
    );
  }
  
  export default Background;