import React from "react";
import "./BackgroundCom.css";

const BackgroundCom = () => {
  return (
    <>
      <section className="backgound container">
        <p>
         <span>Sampurna</span> Group of Companies is located at the heart of Kolkata.
          Sampurna was incorporated as a non-profit company in September 2010
          registered under section 25 of The Company Act 1956. Sampurna
          originally opened its doors in 2007 under the name of Navnirman
          Community Resource Centre (NCRC). NCRC was a wing of a social
          microfinance organization based in Mumbai.
            <br />
            <br />
            <span>In January 2011,</span> Sampurna
          formally started its wide range of socio-economic services including
          family counseling, social awareness and business management training,
          business counseling etc. to uplift the socio-economic conditions of
          vulnerable people at slums of Kolkata and Howrah. In January 2014,
          Sampurna took over the microfinance program of NCRC along with the
          management staffs and field officers. Since the beginning Sampurna is
          receiving financial and technical support from a French organization
          called, Entrepreneurs du Monde (EdM) specialized in Social
          Microfinance. Sampurna consecutively opened it's different wings and
          now has three fully operational projects.
        </p>
      </section>
    </>
  );
};

export default BackgroundCom;
