import React from "react";
import "./SkillComp.css";
import { Link } from "react-router-dom";
import SkillPic from "../../resource/Inner_picture/Skill.jpg";

const SkillComp = () => {
  return (
    <>
      <section className="skill-comp container">
        <div className="skill-comp-left">
        <p>
          <span>Sampurna</span> believes in providing members of the lower income group of
          our society with access to opportunities for comprehensive financial
          and business development. Access to finance and business opportunities
          can help such disadvantaged households to tackle the lag because of
          low or no income. However, skill developmental training programmes
          support them to heal their root cause of poor living standards by
          helping them realize their interests is also imperative. We have
          witnessed that with a small impetus to awaken their entrepreneurial
          intent and skills; and with a little push, they gain the ability to
          jump over the traditional <span>'poverty trap'</span>. Sampurna has enabled
          hundreds of families to dream and eventually turn those dreams into
          reality.
        </p>
        <h5>For more details, visit: <Link to="https://sampurnaskills.co.in" target="-blank"> www.sampurnaskills.co.in</Link></h5>
        </div>
        <div className="skill-comp-right">
            <img src={SkillPic} alt="" />
        </div>
      </section>
    </>
  );
};

export default SkillComp;
