import React from "react";
import "./About_us.css";
import img from "../../resource/Inner_picture/about.jpg";

const Home_about_us = () => {
  return (
    <>
      <section className="about_us">
        <div className="container">
          <div className="about_left">
            <h1>ABOUT US</h1>
            <p>
              We majorly focus on the economically lesser advantaged families to
              secure a better future for them. We strongly believe that it is
              not merely enough to give access to financial relief avenues to
              generate holistic development for them. Therefore, our motto is to
              provide a wide range of socio-economic services along with
              required financial services, homing in on personal aptitude and
              dedication, so that these families can walk towards success and
              sustainable brighter future.
            </p>
          </div>
          <div className="about_right">
            <div className="group_pic">
              <img src={img} alt="About Us" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home_about_us;
