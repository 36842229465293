import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Autoplay } from "swiper";
import { Link } from "react-router-dom";
import "./Lender.css";
import esaf from "../../resource/Lender/esaf.jpg";
import jana from "../../resource/Lender/Janalogo.jpg";
import amfi from "../../resource/Lender/amfi.jpg";
import highmark from "../../resource/Lender/highmark.png";
import dhfl from "../../resource/Lender/dhfl.jpg";
import equifax from "../../resource/Lender/equifax.jpg";
import mix from "../../resource/Lender/mix.jpg";
import sadhan from "../../resource/Lender/sadhan.jpg";
import darru from "../../resource/Lender/darru.jpg";
import bnp from "../../resource/Lender/Bnp.jpg";
import Solidaire from "../../resource/Lender/solidaire.jpg";
import dumonde from "../../resource/Lender/dumonde.jpg";
import saintDo from "../../resource/Lender/saintDo.jpg";
import avanti from "../../resource/Lender/avanti.jpg";
import planet from "../../resource/Lender/planet.png";

let lender = [
  { name: "Esaf", path: esaf },
  { name: "Jana", path: jana },
  { name: "Amfi", path: amfi },
  { name: "highmark", path: highmark },
  { name: "DHFL", path: dhfl },
  { name: "Equifax", path: equifax },
  { name: "Mix", path: mix },
  { name: "Sadhan", path: sadhan },
  { name: "Darru", path: darru },
  { name: "BNP", path: bnp },
  { name: "Solidaire", path: Solidaire },
  { name: "Du monde", path: dumonde },
  { name: "Saint Do", path: saintDo },
  { name: "Avanti", path: avanti },
  { name: "Whole Planet", path: planet },
];

const Lender = () => {
  return (
    <>
      <section className="partner">
        <div className="container">
          <h1>Lenders & Partners</h1>
          <Swiper
            slidesPerView={2}
            loop={true}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 3,
                loop: true,
              },
              768: {
                slidesPerView: 4,
                loop: true,
              },
              1024: {
                slidesPerView: 5,
                loop: true,
              },
              1200: {
                slidesPerView: 6,
                loop: true,
              },
            }}
            autoplay={{
                delay: 2000,
              }}
          >
            {lender.map((lenderItem, index) => (
              <SwiperSlide key={index}>
                <Link to="Network" className="lender_item">
                  <img src={lenderItem.path} alt={lenderItem.name} />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Lender;
