import React, { useEffect } from 'react';
import HomeHero  from "../Home_hero_component/Home_hero.js";
import Homeservice  from "../Home_services_component/Service.js";
import Aboutus  from "../Home_aboutus_component/About_us.js";
import Visionmission from "../Home_mission_component/Vision_mission.js";
import Testimonial from "../Home_testimonial_component/Testimonial.js";
import Lender from "../Home_lender_component/Lender.js";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  useEffect(() => {
    document.title = 'Home | Sampurna';
  }, []);
    return (
      <>
      <HomeHero />
      <Homeservice />
      <Aboutus />
      <Visionmission />
      <Testimonial />
      <Lender />
      </>
    );
  }
  
  export default Home;