import React from "react";
import "./OfficeCom.css";
import Map from "../../resource/Banner/map.png";

const OfficeCom = () => {
  return (
    <>
      <section className="geographical_presence">
        <div className="container">
          <div className="geo">
            <h1>Geographical Presence</h1>
            <p>
              Sampurna has 45 branches across the 10 districts in West Bengal. These
              areas of action are chosen to be cloth to our target population.
            </p>
          </div>
          <div className="map">
            <div className="branch_list">
              <ol>
                <li>
                  Kolkata
                  <ul>
                    <li>- 3 Branch</li>
                  </ul>
                </li>
                <li>
                  Purba Bardhaman
                  <ul>
                    <li>- 4 Branch</li>
                  </ul>
                </li>
                <li>
                  Howrah
                  <ul>
                    <li>- 5 Branch</li>
                  </ul>
                </li>
                <li>
                  Hoogly
                  <ul>
                    <li>- 3 Branch</li>
                  </ul>
                </li>
                <li>
                  Purba Medinipur
                  <ul>
                    <li>- 6 Branch</li>
                  </ul>
                </li>
                <li>
                  Paschim Medinipur
                  <ul>
                    <li>- 6 Branch</li>
                  </ul>
                </li>
                <li>
                  Bankura
                  <ul>
                    <li>- 2 Branch</li>
                  </ul>
                </li>
                <li>
                  South 24 Pgs
                  <ul>
                    <li>- 8 Branch</li>
                  </ul>
                </li>
                <li>
                  North 24 Pgs
                  <ul>
                    <li>- 6 Branch</li>
                  </ul>
                </li>
                <li>
                  Birbhum
                  <ul>
                    <li>- 2 Branch</li>
                  </ul>
                </li>
              </ol>
            </div>
            <img src={Map} alt="pic" />
          </div>
        </div>
      </section>
    </>
  );
};

export default OfficeCom;
