import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import GallaryComp from "../Gallary_component/GallaryComp.js";

function Gallary() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Gallery" image={require('../../resource/Banner/header.jpg')}/>
      <GallaryComp />
      </>
    );
  }
  
  export default Gallary;