import React from 'react';
import "./SocialComp.css";
import clinic_img from "../../resource/Banner/Home_BG_3.jpg";

const SocialComp = () => {
  return (
    <>
    <section className="socials">
    <div className="container">
        <div className="con">
            We at <strong>Sampurna</strong> are a microfinance organization that aims to foster development of the
            most vulnerable sections of the society. The target of our social work is the economically disadvantaged
            families and we strongly believe that it is not merely enough to give access to such families to avenues
            of finance to generate holistic development for them. Therefore the focus of our activities is on
            providing a wide range of socio-economic services in addition to the required financial services, homing
            in on personal talent and aptitude, so that such disadvantaged families can develop in a manner that can
            sustainable for a long period of time.<br/><br/>

            Also in the current pandemic situation it has become also much more evident to have a strong social
            impact in the society so as to sustain the core parameters of the business in a seamless flow.<br/>
            <ul>
                <li>• <strong>Sensitization</strong> of the common and creating awareness to the people through
                    virtual mediums during pandemic</li>
                <li>• To have a strong <strong>BCP</strong> in the uncertain times of lockdown.</li>
                <li> • <strong>Responding</strong> to the partners ontime everytime so as to have a strong
                    communication bond growing and have attached with the borrowers in the times of difficulty.</li>
                <li>• To have the <strong>repayments</strong> continue to the survive the business in the trying
                    times of business during lockdown</li>
                <li>• To have <strong>Solutions</strong> to customers in their daily needs and their queries of the
                    product and loans. </li>
            </ul><br/>
            Out of the different initiatives taken in pursuit of the above vision below few are noteworthy which we
            would like to highlight in this august forum.<br/><br/><br/>


            <h1>Descriptions of the social impact initiatives </h1>
            <h2>(Includes all SFBs/MFIs & other players in microfinance domain)</h2>
            <br/><br/>
            <h3>Monetary Grant</h3>
            <br/>
            Ever since COVID-19 has hit the country many informal workers, artisans, weavers, entrepreneurs, small
            vendors, people from other unorganized workers have lost their livelihood and received huge blows with
            the outbreak of the virus and present face a plethora of issues and so project aimed at promoting
            Economic resilience of marginalised families through <strong> financial micro – grants.</strong><br/><br/>

            Sampurna works mostly with women living in slums and rural areas. These women are most engaged in inform
            work or they play very active roles to support their main earning persons. Due to the outbreak of
            COVID-19 and impositions of lockdown by central and state Govt in various times, small vendors and
            business were seriously impacted. The public transport like local metro and train which mostly connect
            with rural and urban people informal labour was not available. As a result, many of daily marginalised
            families lost their job. Small traders in slums has declined their income almost 30-40 % and that
            seriously impacted their daily life. Their working capital was used for daily consumption.<br/>
            Small grant of INR 4000- INR 5000 help them to mitigate the essential family requirement. <br/><br/>

            In order to ensure that the grant reached to target population a committee comprise of senior management
            official is formed. <br/><br/>

            <ul>
                <li> Loan officer and vocational training staffs primarily identify the families. </li>
                <li> Branch manager check the credentials and present the profile to grant committee.</li>
                <li> The grant committee approve the list and send it to finance department </li>
                <li> Finance department make the necessary transaction.</li>
            </ul><br/>

            500 families has already been identifies and approved by the committee.<br/><br/>


            <h3>Amphan Relief Support </h3><br/>

            During the time of cyclone Amphan, which caused massive havoc across the districts in South Bengal, Many
            were left homeless, roof getting blown off, houses were broken etc.<br/>
            As a socially responsible organization, Sampurna went beyond the call of the daily life to support these
            people in whatsoever small way though possible.
            More than 10 families were helped with Tarpaulin for getting relief from the downpour temporarily.<br/>
            Loans were also given to nearly 15 partners at a very marginalised rates for helping them build their
            houses.<br/><br/>


            <h3>Covid'19 safe kit distribution</h3><br/>

            During the time of Covid'19 1st wave, there were lot of fears across the country to get infected as
            vaccines were not yet developed at that phase. Personal hygiene, sanitization, face mask were the main
            deterrent tolls available then.<br/>
            Sampurna took to the occasion then, distributed face masks, bottles of sanitizer, bottles of phenyle
            among the affected families across the slums of both urban and rural areas.<br/>
            It was done in a high challenge mode as, physical travel and communication was totally stopped by the
            government except emergency as the infectivity and mortality was very high. But the zeal and enthusiasm
            of the staffs to help the people in this cause could not be bounded by the rules. More than 80% of the
            employees worked tirelessly to help the people get this safe kits.<br/><br/>



            <h3>Covid'19 safe kit Training </h3><br/>

            Also in addition to the distribution of the above safety kits, Sampurna also though its vocational
            courses tried to impart to the people how to prepare Facemasks, Sanitizers, and phenyl so that they can
            become independent and prepare and same by their own to safeguard their family and near and dear ones
            from the viscous virus and to stop the chain of spreading.<br/>
            Many training sessions were conducted across various platforms and various places which helped many
            families.<br/><br/>


            <h3>Covid'19 Ration Bag</h3><br/>

            During the time when covid'19 rose to its new heights in second wave in May 2021, the conditions of the
            people even worsened. People who were already affected by the first wave having lost their jobs of with
            reduced income. All public transport modes was stopped. Daily income group was worst impacted. There was
            scarcity of food and ration across the place.<br/>
            Sampurna took to this occasion and found a way to support the underprivileged group by delivering ration
            kit comprising of nutritious foods like Rice, Pulses, soyabean which could easily feed a family of 3 for
            a week. And this Ration kit was delivered twice to a family.<br/>
            More than 500 families were distributed this rations kit across 6 districts over a period of more than a
            month a process of ongoing support to the affected families.<br/>
        </div>

        <div className="eclinic">
            <div className="eleft">
                <h1>E-Clinic Services</h1>
                <p>E-Clinics are setups, providing healthcare through tele-medicine. Our efforts are focused on making
                basic primary healthcare more accessible and convenient for underprivileged through technology and
                high-quality customer service. Healthcare is now just one call away.<br/>We provide 24x7 telemedicine
                services, video consultation with doctors when patient visits our branch, providing them medications
                in a convenient subscription fee. <br/>Initially we have started our e-clinic services at out
                Bagbazar branch. Our moto is to serve more people through e-clinic services and expand it in other
                rural and urban areas very soon.</p>
            </div>
            <div className="eright">
                <img src={clinic_img} alt='pic' />
            </div>

        </div>
        </div>

</section>
    </>
  )
}

export default SocialComp;
