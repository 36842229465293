import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import PressCom from "../Press_component/PressCom.js";

function Press() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="On Press" image={require('../../resource/Banner/presss.jpg')}/>
      <PressCom />
      </>
    );
  }
  
  export default Press;