import React from "react";
import "./LenderCom.css";
import esaf from "../../resource/Lender/esaf.jpg";
import jana from "../../resource/Lender/Janalogo.jpg";
import amfi from "../../resource/Lender/amfi.jpg";
import highmark from "../../resource/Lender/highmark.png";
import dhfl from "../../resource/Lender/dhfl.jpg";
import equifax from "../../resource/Lender/equifax.jpg";
import mix from "../../resource/Lender/mix.jpg";
import sadhan from "../../resource/Lender/sadhan.jpg";
import darru from "../../resource/Lender/darru.jpg";
import bnp from "../../resource/Lender/Bnp.jpg";
import Solidaire from "../../resource/Lender/solidaire.jpg";
import dumonde from "../../resource/Lender/dumonde.jpg";
import saintDo from "../../resource/Lender/saintDo.jpg";
import avanti from "../../resource/Lender/avanti.jpg";
import planet from "../../resource/Lender/planet.png";

const LenderCom = () => {
  return (
    <>
      <section className="network container">
        <div className="network-item" id="Esaf">
          <div className="network-left">
            <h1>ESAF Small Finance Bank</h1>
            <p>ESAF Small Finance Bank is an Indian small finance bank providing banking services and small loans to the underbanked. It was founded as a small finance bank in March 2017. ESAF Microfinance started its operations as an NGO in 1992 as Evangelical Social Action Forum.</p>
          </div>
          <div className="network-right">
            <img src={esaf} alt="ESAF" />
          </div>
        </div>
        <div className="network-item" id="Jana">
          <div className="network-left">
            <h1>Jana Small Finance Bank</h1>
            <p>Jana Small Finance Bank is a small finance bank that commenced operations on March 28, 2018, headquartered in Bangalore, India. The Reserve Bank of India issued a licence to the bank under Section 22 of the Banking Regulation Act, 1949.</p>
          </div>
          <div className="network-right">
            <img src={jana} alt="Jana" />
          </div>
        </div>
        <div className="network-item" id="Amfi-WB">
          <div className="network-left">
            <h1>Association of Microfinance Institutions - West Bengal</h1>
            <p>Association of Microfinance Institutions - West Bengal (AMFI-WB) was founded as a self-regulatory body in 2006. Objective of this association was to bring under its umbrella all Community Microfinance organizations that have their presence in West Bengal. Its Mission is to build a field of community development finance in West Bengal to help its members to serve their target clients, particularly women from poor families, in both rural and urban areas in pursuit of establishing stable livelihoods.</p>
          </div>
          <div className="network-right">
            <img src={amfi} alt="Amfi" />
          </div>
        </div>
        <div className="network-item" id="Highmark">
          <div className="network-left">
            <h1>CRIF High Mark Credit Information Services</h1>
            <p>CRIF High Mark Credit Information Services Pvt. Ltd. is an RBI-approved credit bureau in India. It serves retail, agriculture and rural, MSME, commercial and microfinance. The company was incorporated in 2005 and is based in Mumbai.</p>
          </div>
          <div className="network-right">
            <img src={highmark} alt="Highmark" style={{ height: 'auto', width: '60%' }}/>
          </div>
        </div>
        <div className="network-item" id="Pramerica">
          <div className="network-left">
            <h1>Pramerica Life Insurance</h1>
            <p>Pramerica Life Insurance Company Limited is one of the fastest growing life insurance companies in India headquartered in Gurgaon, India. DHFL provide a wide range of life insurance solutions for individuals as well as groups taking care of our customer's various financial protection needs such as securing their child's future, retirement planning, savings and wealth creation.</p>
          </div>
          <div className="network-right">
            <img src={dhfl} alt="Pramerica"  style={{ height: 'auto', width: '50%' }}/>
          </div>
        </div>
        <div className="network-item" id="Equifax">
          <div className="network-left">
            <h1>Equifax</h1>
            <p>Equifax Inc. is an American multinational consumer credit reporting agency headquartered in Atlanta, Georgia and is one of the three largest consumer credit reporting agencies, along with Experian and TransUnion.</p>
          </div>
          <div className="network-right">
            <img src={equifax} alt="Equifax" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
        <div className="network-item" id="MIX">
          <div className="network-left">
            <h1>MIX</h1>
            <p>MIX Market provides instant access to the data, analysis, and tools that deliver key insights into the financial inclusion sector. Whether you are a Financial Service Provider (FSP), investor, or policymaker, the MIX Market platform can help you assess financial, operational, and social performance.</p>
          </div>
          <div className="network-right">
            <img src={mix} alt="MIX"  style={{ height: 'auto', width: '50%' }}  />
          </div>
        </div>
        <div className="network-item" id="Sa-Dhan">
          <div className="network-left">
            <h1>Sa-Dhan</h1>
            <p>Sa-Dhan has about 220 members working in 33 states/UTs and over 600 districts, which includes both, for Profit and Not for Profit MFIs, SHG promoting institutions, banks, rating agencies, capacity-building institutions etc. Sa-Dhan's members with diverse legal forms and operating models, reach out to approximately 44 million clients with loan outstanding of more than ₹1,27,801 crores. Sa-Dhan is also recognized as a National Support Organization (NSO) by National Rural Livelihood Mission (NRLM).</p>
          </div>
          <div className="network-right">
            <img src={sadhan} alt="Sa-Dhan"  style={{ height: 'auto', width: '50%' }}  />
          </div>
        </div>
        <div className="network-item" id="Dariu-Foundation">
          <div className="network-left">
            <h1>The Dariu Foundation</h1>
            <p>We provide access to digital education for under-served, young people. In most schools, there are no computers for students to learn basic computer skills.</p>
          </div>
          <div className="network-right">
            <img src={darru} alt="The Dariu Foundation" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
        <div className="network-item" id="BNP-Paribas">
          <div className="network-left">
            <h1>BNP Paribas</h1>
            <p>BNP Paribas established their first branches in China and India in 1860. They invested in the products and people to provide simple and effective solutions in the world's fastest growing markets. They have expertise in capital markets, advisory and financing businesses. They have spread their branches in 55 countries among all over the world.</p>
          </div>
          <div className="network-right">
            <img src={bnp} alt="BNP Paribas" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
        <div className="network-item" id="Microfinance-Solitaire">
          <div className="network-left">
            <h1>Microfinance Solitaire</h1>
            <p>Microfinance Solidaire is a french company that linked the investor with micro-finance institutions in order to provide them funds. the company lent money to social micro-finance institutions with low interest rates.</p>
          </div>
          <div className="network-right">
            <img src={Solidaire} alt="Microfinance Solitaire" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
        <div className="network-item" id="Entrepreneurs-Du-Monde">
          <div className="network-left">
            <h1>Entrepreneurs Du Monde</h1>
            <p>The French social microfinance NGO was at the root of the creation of NCRC, which later became Sampurna.Although Sampurna is now entirely independent, Entrepreneurs du Mondeprovides donations that greatly help Sampurna expand its services, but also provides moral and technical support through many visits in India every year.</p>
          </div>
          <div className="network-right">
            <img src={dumonde} alt="Entrepreneurs du Monde" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
        <div className="network-item" id="Saint-Do">
          <div className="network-left">
            <h1>Saint Do</h1>
            <p>Students from Saint Do French high school come every year to visit our organisation and usually spend a few days on the field. They have given positive feedbacks about Sampurna's relationship with the partners.</p>
          </div>
          <div className="network-right">
            <img src={saintDo} alt="Saint Do" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
        <div className="network-item" id="Avanti-Finance">
          <div className="network-left">
            <h1>Avanti Finance</h1>
            <p>Avanti Finance Private Limited is a 6 years 6 months old Private Company incorporated on 01 Aug 2016. Its registered office is in Bangalore, Karnataka, india.</p>
          </div>
          <div className="network-right">
            <img src={avanti} alt="Avanti Finance" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
        <div className="network-item" id="Whole-Planet-Foundation">
          <div className="network-left">
            <h1>Whole Planet Foundation</h1>
            <p>Whole Planet Foundation is a private, nonprofit organization established by Whole Foods Market and dedicated to poverty alleviation. They aim to empower the world’s poorest people with microcredit in places where Whole Foods Market sources products.</p>
          </div>
          <div className="network-right">
            <img src={planet} alt="Whole Planet Foundation" /* style={{ height: 'auto', width: '50%' }} */ />
          </div>
        </div>
      </section>
    </>
  );
};

export default LenderCom;
