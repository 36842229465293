import React, { useState } from 'react';
import './PressCom.css';
import newspaper1 from '../../resource/newspapercut/1.jpg';
import newspaper2 from '../../resource/newspapercut/2.jpg';
import newspaper3 from '../../resource/newspapercut/3.jpg';
import newspaper4 from '../../resource/newspapercut/4.jpg';
import newspaper5 from '../../resource/newspapercut/5.jpg';
import newspaper6 from '../../resource/newspapercut/6.jpeg';
import newspaper7 from '../../resource/newspapercut/7.jpeg';

const PressCom = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupImage, setPopupImage] = useState('');

  const handleImageClick = (event) => {
    setShowPopup(true);
    setPopupImage(event.target.getAttribute('src'));
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <section className="cutout">
      <div className="container">
        <h1>Newspaper Cutout</h1>
        <div className="newspaper">
          <div className="newspaper_item">
            <img src={newspaper1} alt="" onClick={handleImageClick} />
          </div>
          <div className="newspaper_item">
            <img src={newspaper2} alt="" onClick={handleImageClick} />
          </div>
          <div className="newspaper_item">
            <img src={newspaper3} alt="" onClick={handleImageClick} />
          </div>
          <div className="newspaper_item">
            <img src={newspaper4} alt="" onClick={handleImageClick} />
          </div>
          <div className="newspaper_item">
            <img src={newspaper5} alt="" onClick={handleImageClick} />
          </div>
          <div className="newspaper_item">
            <img src={newspaper6} alt="" onClick={handleImageClick} />
          </div>
          <div className="newspaper_item">
            <img src={newspaper7} alt="" onClick={handleImageClick} />
          </div>
        </div>
        {showPopup && (
          <div className="popup-img">
            <span onClick={handleClosePopup}>&times;</span>
            <img src={popupImage} alt="" />
          </div>
        )}
      </div>
    </section>
  );
};

export default PressCom;
