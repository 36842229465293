import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Autoplay } from "swiper";
import "./Testimonial.css";
import ruksana from "../../resource/Testimonial/ruksana.jpg";
import Rupa from "../../resource/Testimonial/Rupa.jpg";
import Sazia from "../../resource/Testimonial/Sazia.jpeg";
import sonali from "../../resource/Testimonial/sonali.jpg";

const Testimonial = () => {
  return (
    <>
      <section className="testimonial">
        <div className="container">
          <div className="testi_heading">
            <h1>Testimonial</h1>
          </div>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="test_left">
              <div className="test_img" style={{ backgroundImage: `url(${sonali})` }}></div>
              </div>
              <div className="test_right">
                <p>
                  Sarisha, Koshiyari's homemaker Sonali Pradhan's family had
                  lost all means of income when Sonali came across Sampurna. The
                  workers helped her through thick and thin by providing her
                  with a loan and planning her comeback as a small business
                  owner. With the evermore support of the organization's tactful
                  financial consultation, Sonali is now a happy soul who spares
                  enough profit after repaying her loan and keeping aside the
                  required capital for running her shop.
                </p>
                <h2>Sonali Pradhan</h2>
                <h4>- Keshiary</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="test_left">
              <div className="test_img" style={{ backgroundImage: `url(${Sazia})` }}></div>
              </div>
              <div className="test_right">
                <p>
                  Kamarhati's Sazia had no concrete idea of how to take care of
                  her pocket money as she is nothing but a school student. She
                  knocked on the door of Sampurna and enrolled in the course she
                  related to the most: Mehendi design. And only after 4 months
                  or so, as she got detailed knowledge about designs, and
                  patterns. With this knowledge, she found an honest and
                  efficient way to earn 500-600 rupees by spending only 2-3
                  hours a day.
                </p>
                <h2>Sazia Bano</h2>
                <h4>Kamarhati</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="test_left">
              <div className="test_img" style={{ backgroundImage: `url(${Rupa})` }}></div>
              </div>
              <div className="test_right">
                <p>
                  Lakhikantapur's Rupa Mistri was no exception to being a prey
                  of the deadly virus and its effects. Her entire family
                  suffered financial strain. However, Sampurna's workers reached
                  them just in time to save Rupa, her husband, and her son
                  during such traumatizing time. Their wise advice and financial
                  aid in the form of a loan have helped the family stand its
                  feet again
                </p>
                <h2>Rupa Mistri</h2>
                <h4>Lakhikantapur</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="test_left">
              <div className="test_img" style={{ backgroundImage: `url(${ruksana})` }}></div>
              </div>
              <div className="test_right">
                <p>
                  Ruksana Sardar of Canning Amraberia never thought that her
                  dream of establishing herself as a businesswoman would be
                  possible. As she approached Sampurna, the workers dedicated
                  their time to sorting her financial strain and with the help
                  of her received loan, she is now an independent tailor capable
                  to live her life withheld high!
                </p>
                <h2>Ruksana Sardar</h2>
                <h4>Canning</h4>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
