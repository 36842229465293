import React from 'react';
import "./OverviewCom.css";

const OverviewCom = () => {
  return (
    <>
      <section className="content container">
    <h1>Overview</h1>
    <div className="paragraph">
        <p>Over the past two decades, Indian economy has advanced to become the sixth largest economy in the world,
            the fastest rising major economy. However, the majority of this growth cater to middle and upper classes
            of the country. The inequitable distribution of wealth in West Bengal – the hub of our activities is
            reflected in the fact that about 30 % of the urban population in West Bengal lies below the Poverty
            line. </p>
    </div>
    <div className="paragraph">
        <h4 className="content-head">Status quo</h4>
        <p>Kolkata, the metropolitan capital of Bengal with a population of more than 15 million, is a prime example
            of the discriminative wealth distribution. Poor hygiene and lack of access to basic amenities like
            medication, ration, education and employment opportunities bug the financially challenged throughout
            their entire life. So, there is urgent need to pull these vulnerable sections of the society up towards
            the growth of India.</p>
    </div>
    <div className="paragraph">
        <h4 className="content-head">We, the believers</h4>
        <p>Sampurna believes in providing them with access to opportunities for comprehensive financial and business development as well as social services. Access to finance and business opportunities can help such disadvantaged households to tackle the lag because of low or no income. Support to heal their root cause of poor living standards by helping them realize their interests is also imperative. We have witnessed that with a small impetus to awaken their entrepreneurial intent and skills and with a little push, they gain the ability to jump over the traditional 'poverty trap'.</p>
    </div>
    <div className="paragraph">
        <h4 className="content-head">Reality check</h4>
        <p>Sampurna is very aware of this socio-economic context in Bengal's various regions, and therefore continuously strives to provide not only financial assistance but also adequate guidance to such marginalized families so that they become self-sufficient and can carve new chapters in their lives.</p>
    </div>
    <div className="paragraph">
        <h4 className="content-head">Target People</h4>
        <p>The people that Sampurna wants to serve and provide its services are those that are vulnerable - not only financially, but also socially. We specifically believe that it is critical that the women folk of disadvantaged household are given the opportunity to prove themselves. We offer such individuals a wide range of services to not only help them to finance their ideas about also offer suggestions and guidance to them for the best use of finances through various skill development services.</p>
    </div>
</section>
    </>
  )
}

export default OverviewCom;
