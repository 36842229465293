import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import CareerCom from "../Career_component/CareerCom.js"

function Career() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Career" image={require('../../resource/Banner/career.jpg')}/>
      <CareerCom />
      </>
    );
  }
  
  export default Career;