import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./Hero.css";


const Hero_content = (props) => {
    useEffect(() => {
      document.title = props.title + ' | Sampurna';
    }, []);
    const titleClass = props.title.includes(' ')
    ? props.title.replace(' ', '-')
    : '';
  return (
    <>
       <div className={`hero ${titleClass}`} style={{backgroundImage: `url(${props.image})`}}>
        <div className="container">
                <h1>{props.title}</h1>
                <p><Link to="/">Home</Link> / {props.title}</p>
        </div>
    </div>
    </>
  )
}

export default Hero_content;
