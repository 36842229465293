import React from "react";
import "./FAQCom.css";

const FAQCom = () => {
  const handleClick = (event) => {
    const faq = event.currentTarget.closest(".faq");
    faq.classList.toggle("open");
    const answer = faq.querySelector(".answer");
    answer.style.display = answer.style.display === "block" ? "none" : "block";
  };

  return (
    <section className="faqs">
      <div className="container faqs_container">
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>What is Sampurna, and what does it do?</h4>
            <p className="answer">
              Sampurna is a brand that works towards microfinance and skill
              development projects, along with corporate social responsibility
              (CSR) initiatives. The brand aims to provide financial and other
              resources to underprivileged communities and support them in
              improving their livelihoods.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>How does Sampurna choose the projects it supports?</h4>
            <p className="answer">
              Sampurna selects projects based on their impact potential,
              sustainability, and alignment with the brand's values and
              objectives. The brand conducts extensive research and analysis
              before selecting a project and works closely with its partners to
              monitor and evaluate the project's progress.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>What skill development projects are Sampurna involved in?</h4>
            <p className="answer">
              Sampurna is involved in various skill development projects,
              including providing vocational training to individuals, organizing
              entrepreneurship development programs, and promoting skill-based
              education to different age groups.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>What are the CSR projects that Sampurna is involved in?</h4>
            <p className="answer">
              Sampurna is involved in various CSR projects, including supporting
              education, healthcare, environmental sustainability, and disaster
              relief initiatives. The brand works with various NGOs, government
              agencies, and other organizations to implement these projects.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>
              What are the microfinance projects that Sampurna is involved in?
            </h4>
            <p className="answer">
              Sampurna is involved in various microfinance projects, including
              providing small loans to low-income individuals and small business
              owners, developing financial literacy programs, and promoting
              financial inclusion.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>
              How can individuals and organizations get involved with Sampurna's
              projects?
            </h4>
            <p className="answer">
              Individuals and organizations can get involved with Sampurna's
              projects by partnering with the brand, volunteering their time and
              skills, donating funds or resources, or spreading awareness about
              the brand's initiatives. Interested parties can contact Sampurna
              directly to learn more about how they can get involved.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>Is Sampurna a for-profit or non-profit organization?</h4>
            <p className="answer">
              Sampurna is a for-profit organization that operates with a social
              mission. The brand aims to generate sustainable returns while
              making a positive social impact.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>What is the geographic focus of Sampurna's projects?</h4>
            <p className="answer">
              Sampurna's projects are primarily focused on India, where the
              brand is based. However, the brand also works with partners in
              other developing countries to implement its initiatives.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>How does Sampurna measure the impact of its projects?</h4>
            <p className="answer">
              Sampurna uses a variety of metrics to measure the impact of its
              projects, including financial performance, social performance, and
              environmental performance. The brand also conducts regular
              monitoring and evaluation activities to track the progress of its
              initiatives.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>
              How does Sampurna ensure the sustainability of its projects?
            </h4>
            <p className="answer">
              Sampurna focuses on developing sustainable projects that can
              continue to have a positive impact in the long term. The brand
              works closely with its partners to ensure that projects are
              designed with sustainability in mind and that they have a clear
              plan for continued operation after the initial phase is completed.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>
              Does Sampurna have any partnerships with other organizations?
            </h4>
            <p className="answer">
              Yes, Sampurna partners with a variety of organizations, including
              NGOs, government agencies, and private sector companies. These
              partnerships enable the brand to leverage additional resources and
              expertise to maximize the impact of its projects.
            </p>
          </div>
        </article>
        <article className="faq">
          <div className="faq_icon" onClick={handleClick}>
            <i className="fa-solid fa-plus"></i>
          </div>
          <div className="question_answer">
            <h4>
              How can I contact Sampurna if I have additional questions or want
              to get involved?
            </h4>
            <p className="answer">
              You can visit Sampurna's website and/or social media pages to
              learn more about the brand's projects and initiatives, and to
              contact them directly. The website also provides information on
              how to donate to the brand or volunteer your time and skills.
            </p>
          </div>
        </article>
      </div>
    </section>
  );
};

export default FAQCom;
