import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const year = new Date().getFullYear();

const Footer = () => {
  return (
    <>
      <footer className="foot">
        <div className="container">
          <div className="company_info finance">
            <h1>Financial Services</h1>
            <Link to="https://sampurnafinance.co.in/" target="_blank">
              Sampurna Financial Services <br /> Private Limited
            </Link>
            <div className="quicklink">
              <p>
                Sampurna Financial Services Pvt. Ltd. is a business
                correspondence dedicated to serving low-income groups with
                microloans and group loans. Sampurna Finance provides access to
                credit for individuals and groups who often face limited
                opportunities for borrowing.
              </p>
            </div>
          </div>
          <div className="company_info skill">
            <h1>Skills Development Services</h1>
            <Link to="https://sampurnaskills.co.in" target="_blank">
              Sampurna Skills Development <br /> Private Limited
            </Link>
            <div className="quicklink">
              <p>
                Sampurna Skills Development Pvt. Ltd. is a company focused on
                empowering individuals through affordable courses in computer
                skills, makeup and beauty, and dress designing. With a strong
                commitment to accessibility, Sampurna aims to bridge the gap
                between education and employment.
              </p>
            </div>
          </div>
          <div className="company_info traning">
            <h1>Social Services</h1>
            <Link to="https://stepindia.in/" target="_blank">
              Sampurna Training And Entrepreneurship Programme
            </Link>
            <div className="quicklink">
              <p>
                Sampurna Training and Entrepreneurship Programme is a non-profit
                entity working towards the betterment of the low income families
                through various social initiatives and opportunities.
              </p>
            </div>
          </div>
          <div className="company_info address">
            <h1>Get in touch</h1>
            <ul>
              <Link
                to={"https://maps.app.goo.gl/WhEjaXoj8dB6avT38"}
                target="_blank"
              >
                <li>
                  <i
                    className="fa-solid Grievance fa-map-location-dot"
                    style={{ fontSize: "1.2rem", marginTop: "5px" }}
                  ></i>
                  <p style={{ fontWeight : "bold" }}>
                    {" "}
                    DN-30, DN block, <br /> Sector v, Saltlake City,
                    Kolkata - 700091
                  </p>
                </li>
              </Link>
              <li>
                <i
                  className="fa-solid fa-headset"
                  style={{ fontSize: "1.2rem", marginTop: "5px" }}
                />
                <Link to={"tel:+03335081849"}>
                  <p style={{ fontWeight : "bold" }}>
                    Customer Grievance No. <br /> (033) 3508-1849{" "}
                  </p>
                </Link>
              </li>
              <li>
                <i
                  className="fa-brands whatsapp fa-whatsapp"
                  style={{ fontSize: "1.2rem", marginTop: "5px" }}
                ></i>
                <Link to={"https://wa.me/+918100971969"} target="_blank">
                  <p style={{ fontWeight : "bold" }}>+91 81009-71969</p>
                </Link>
              </li>
              <li style={{ marginTop: "10px" }} className="social-icons">
                <Link
                  to={"https://www.facebook.com/profile.php?id=100089912055180"}
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link
                  to={"https://www.youtube.com/@sampurna6984/videos"}
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube"></i>
                </Link>
                <Link
                  to={"https://www.instagram.com/sampurna_projects/"}
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <section className="copyright_policy">
        <div className="container">
          <div className="copyright">
            <p>
              &#169; {year} <Link to="/">SAMPURNA</Link>. All Rights Reserved
            </p>
          </div>
          <div className="policy">
            <Link to="/PrivacyPolicy">Privacy Policy</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
