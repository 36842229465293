import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import OfficeCom from "../Office_component/OfficeCom.js";

function Offices() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Geographical Presence" image={require('../../resource/Banner/branch.jpeg')}/>
      <OfficeCom />
      </>
    );
  }
  
  export default Offices;