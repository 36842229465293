import React, { useEffect } from 'react'
import HeroContent from '../Hero_component/Hero.js';
import PrivacyComp from '../Privacy_components/PrivacyComp.js';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
         <HeroContent title ="Privacy Policy" image={require('../../resource/Banner/privacy_policy.jpg')}/>
         <PrivacyComp />
    </>
  )
}

export default PrivacyPolicy;