import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import MicrofinanceComp from "../Microfinance_component/MicrofinanceComp.js";

function Microfinance() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Microfinance" image={require('../../resource/Banner/microfinance.jpg')}/>
      <MicrofinanceComp />
      </>
    );
  }
  
  export default Microfinance;