import React from "react";
import "./MicrofinanceComp.css";
import MFI from "../../resource/Inner_picture/MIF.jpg";

const MicrofinanceComp = () => {
  return (
    <>
      <section class="mf">
        <div class="container">
          <div class="mfleft">
            <h1>Microfinance Services</h1>
            <p>
              Sampurna works mostly in suburban and rural slums. In order to
              provide financial accessibility Sampurna provides microcredit to
              the underdog population <br /><br /> Sampurna proposes to form a group of 7
              to 10 women who individually get the disbursed loan amount
              post-approval. Each partner has her own{" "}
            </p>
          </div>
          <div class="mfright">
            <img src={MFI} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default MicrofinanceComp;
