import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import LenderCom from "../Lender_component/LenderCom.js";

function Network() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
       <Hero title ="Our Networks" image={require('../../resource/Banner/lender.jpg')}/>
       <LenderCom />
      </>
    );
  }
  
  export default Network;