import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import VisionCom from "../Vision_component/VisionCom.js";

function Vision() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Vision & Mission" image={require('../../resource/Banner/vision.jpg')}/>
      <VisionCom />
      </>
    );
  }
  
  export default Vision;