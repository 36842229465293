import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import OverviewCom from "../Overview_component/OverviewCom.js"

function Overview() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Overview" image={require('../../resource/Banner/overview.jpg')}/>
      <OverviewCom />
      </>
    );
  }
  
  export default Overview;