import React, { useEffect } from 'react';
import SocialComp from "../Social_service_component/SocialComp.js";
import Hero from "../Hero_component/Hero.js";

function Socialservice() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Social Services" image={require('../../resource/Banner/Social_service.jpeg')}/>
      <SocialComp />
      </>
    );
  }
  
  export default Socialservice;