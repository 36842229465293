import React from "react";
import "./Vision_mission.css";
import img from "../../resource/Inner_picture/Vision_mission.jpg";

const Vision_mission = () => {
  return (
    <>
    <section className="vision">
        <div className="container">
            <div className="vision_left">
                {/* <h1>OUR VISION & MISSION</h1> */}
                <div className="first_one">
                    <h2>Vision</h2>
                    <p>A flourishing society where vulnerable families are empowered to improve and strengthen their standard of living. </p>
                </div>
                <div className="sec_one">
                    <h2>Mission</h2>
                    <p>To provide low income and disadvantaged families with sustainable adapted and innovative financial, business development, access to employment and social services, building their self-reliance and capacity to realize their wishes</p>
                </div>
            </div>
            <div className="vision_right">
                <div className="vision_pic">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    </section>
 </>
  );
};

export default Vision_mission;
