import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import FAQCom from "../FAQ's_component/FAQCom.js";

function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="FAQ" image={require('../../resource/Banner/faq.jpg')}/>
      <FAQCom />
      </>
    );
  }
  
  export default FAQ;